.App {
  text-align: center;
}

.App-logo {
  animation: pulse infinite 5s linear;
  height: 30px;
  margin-right: 10px;
}

.App-header {
  background-color: #222;
  max-height: 100%;
  padding: 0px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.pulse {
  margin:100px;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #cca92c;
  cursor: pointer;
  position:relative;
  border: 4px solid #cca92c;
}

.pulse:after {
  content:"";
  display:block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 3px solid #CBA82C;
  position:absolute;
  top:-5px;
  left:-5px;
  animation: pulse 2s ease 0s infinite;
}

@keyframes pulse {
  0% {
      opacity: 1;
      transform: scale(1);
  }
  50% {
      opacity: 0.3;
      transform: scale(1);
  }
  100% {
      opacity: 1;
      transform: scale(1);
  }
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
